import React from 'react'

const Sanbase = ({ className }) => (
  <svg
    width='24'
    height='24'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.34 12c0 .53-.43.96-.97.96a.97.97 0 01-.98-.96c0-.53.44-.96.98-.96s.97.43.97.96z' />
    <path d='M16.63 12.96c.54 0 .98-.43.98-.96a.97.97 0 00-.98-.96.97.97 0 00-.97.96c0 .53.43.96.97.96z' />
    <path d='M13.95 8.37a3.7 3.7 0 00-2.5-.38c-.78.18-1.46.72-1.59 1.61-.1.74.12 1.33.51 1.78.36.43.87.75 1.3 1.01.45.28.83.51 1.11.78.26.26.4.51.4.86 0 .39-.12.59-.25.7a1.1 1.1 0 01-.67.24 3.08 3.08 0 01-1.88-.55l-.69.89c.65.5 1.71.84 2.65.77.47-.03.97-.18 1.36-.53.4-.36.6-.88.6-1.52 0-.71-.3-1.24-.72-1.65-.4-.38-.9-.68-1.32-.93-.44-.28-.79-.5-1.02-.78-.2-.24-.31-.51-.26-.91.05-.34.28-.58.73-.69.46-.1 1.1-.04 1.7.29l.54-.99z' />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.63 8.47a3.4 3.4 0 00-2.13-.26c-.72.17-1.3.65-1.41 1.42-.1.68.1 1.2.45 1.6.34.4.81.7 1.24.97l.1.06c.4.25.78.48 1.06.75.29.28.46.6.46 1.02 0 .44-.13.7-.31.87-.2.17-.47.27-.82.3a3.36 3.36 0 01-1.84-.47l-.41.53c.61.39 1.51.65 2.3.6a2 2 0 001.23-.48c.34-.3.53-.76.53-1.35 0-.64-.28-1.11-.66-1.49a6.6 6.6 0 00-1.17-.83l-.1-.07a4.47 4.47 0 01-1.08-.82 1.3 1.3 0 01-.31-1.1c.03-.21.12-.4.28-.56.16-.15.37-.25.62-.31a2.7 2.7 0 011.66.2l.31-.58zm-2.23-.7c.82-.2 1.8-.05 2.65.4l.2.1-.74 1.4-.2-.11a2.33 2.33 0 00-1.55-.27.85.85 0 00-.4.2.5.5 0 00-.15.3c-.05.33.04.54.2.73a4.08 4.08 0 001.08.8 7 7 0 011.25.9c.44.43.8 1.02.8 1.81a2.2 2.2 0 01-.69 1.69c-.43.4-.99.55-1.5.59-.99.07-2.1-.3-2.8-.82l-.18-.14.97-1.24.18.13c.4.3 1.13.55 1.72.5.28-.02.45-.1.54-.18.07-.06.17-.2.17-.53 0-.27-.1-.47-.33-.7a5.78 5.78 0 00-1.08-.75 5.5 5.5 0 01-1.34-1.05 2.4 2.4 0 01-.56-1.96c.14-1.01.91-1.6 1.76-1.8zM6.17 12c0-.66.54-1.19 1.2-1.19.66 0 1.2.53 1.2 1.19a1.2 1.2 0 01-1.2 1.19A1.2 1.2 0 016.17 12zm1.2-.73a.74.74 0 00-.75.73c0 .4.33.73.75.73s.75-.33.75-.73c0-.4-.33-.73-.75-.73zm9.26 0a.74.74 0 00-.75.73c0 .4.33.73.75.73s.75-.33.75-.73c0-.4-.33-.73-.75-.73zm-1.2.73c0-.66.54-1.19 1.2-1.19.66 0 1.2.53 1.2 1.19a1.2 1.2 0 01-1.2 1.19 1.2 1.2 0 01-1.2-1.19z'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 20.43a8.43 8.43 0 100-16.86 8.43 8.43 0 000 16.86zM21.1 12a9.1 9.1 0 11-18.2 0 9.1 9.1 0 0118.2 0z'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 20.36a8.36 8.36 0 100-16.72 8.36 8.36 0 000 16.72zM12 22a10 10 0 100-20 10 10 0 000 20z'
    />
  </svg>
)

export default Sanbase
