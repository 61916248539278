import React from 'react'

const Telegram = ({ className }) => (
  <svg
    width='24'
    height='24'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.1771 17.7955L19.3151 4.80993L4.05546 11.4869L5.96129 12.474C6.15838 12.3603 6.39009 12.2269 6.64789 12.0786C7.48037 11.5998 8.58615 10.966 9.67715 10.3474C10.7657 9.73019 11.8486 9.12299 12.6316 8.69982C13.0168 8.49161 13.3548 8.31413 13.5893 8.20242L13.5929 8.20072C13.6745 8.16183 13.8595 8.0737 14.0427 8.02337C14.0877 8.01101 14.2338 7.97128 14.4217 7.9665C14.513 7.96418 14.7128 7.96629 14.949 8.04731C15.2059 8.1354 15.5925 8.35275 15.8161 8.81991C16.0277 9.26235 15.9658 9.66716 15.9105 9.87013C15.8547 10.0746 15.7685 10.2206 15.7245 10.2895C15.6345 10.4306 15.5398 10.5269 15.5062 10.5607C15.2141 10.854 13.5128 12.3684 11.9634 13.7422C11.1715 14.4443 10.3953 15.1306 9.81722 15.6413L9.03421 16.3327V18.7212L10.8036 16.5501C11.2343 16.0217 11.966 15.8519 12.5839 16.1371L16.1771 17.7955ZM7.87267 15.3606C7.87267 15.3606 8.60509 14.7148 9.58231 13.8505C10.7758 12.795 12.3343 11.4138 13.3698 10.4854C13.9291 9.98386 14.3358 9.61449 14.4498 9.5C14.4842 9.4655 14.4736 9.45805 14.424 9.47409C14.2783 9.52128 13.7958 9.77163 13.1302 10.1343C11.9471 10.779 10.1857 11.7788 8.70968 12.6233C7.2071 13.4831 6.00024 14.182 6.00024 14.182L2.58059 12.4108C1.78003 11.9952 1.81395 10.8302 2.64162 10.4692L19.5028 3.09157C20.3102 2.73726 21.1785 3.46632 20.9681 4.33142L17.5077 18.6511C17.3449 19.3255 16.5917 19.673 15.9742 19.3528L11.9599 17.5L9.52818 20.4837C8.9651 21.4102 7.54023 21.0083 7.54023 19.925V16.1372C7.54023 15.8443 7.6623 15.565 7.87267 15.3606ZM13.3934 8.43934C13.3935 8.43928 13.3935 8.43931 13.3934 8.43934Z' />
  </svg>
)

export default Telegram
