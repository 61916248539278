import React from 'react'

const LinkedIn = ({ className }) => (
  <svg
    width='24'
    height='24'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4c0-1.1.9-2 2-2zm0 2v16h16V4H4zm9 5c-.52 0-1.06.16-1.55.45L11 9h-1v7h2v-4c0-.58.6-1 1-1h1c.4 0 1 .42 1 1v4h2v-4c0-1.85-1.6-3-3-3h-1zM8 8a1 1 0 100-2 1 1 0 000 2zM7 9v7h2V9H7z'
    />
  </svg>
)

export default LinkedIn
